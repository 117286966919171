<template>
  <b-badge :variant="statusVariant" :class="classesComputed">
    {{statusLabel}}
  </b-badge>
</template>

<script>

export default {
  name: 'Badge',
  props: {
    status: String,
    statuses: String,
  },
  computed: {
    statusesList() {
      return this.$store.getters['tableUtils/getStatuses'](this.statuses);
    },
    statusVariant() {
      const a = this.statusesList[this.status];
      if (a?.theme) {
        return a.theme;
      }
      return 'primary';
    },
    statusLabel() {
      const a = this.statusesList[this.status];
      if (a?.label) {
        return a.label;
      }
      return `Label non presente per ${this.status}`;
    },
    classesComputed() {
      return `bg-${this.statusVariant}`;
    },
  },
  methods: {},
};
</script>

<style scoped>

</style>
